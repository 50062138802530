<template>
  <div class="margin-top-30 padding-bottom-30">
    <div style="margin: 0 5vw;">
      <div class="detail" v-if="detail.orderId">
        <div class="detail_left">
          <div class="detail_box">
            <div class="detail_box_title">基础信息</div>
            <div class="detail_box_item">
              <div class="detail_box_item_left">订单号</div>
              <div class="detail_box_item_right">{{detail.orderId}}</div>
            </div>
            <div class="detail_box_item">
              <div class="detail_box_item_left">订单类型</div>
              <div class="detail_box_item_right">{{detail.businessType == 2 ? '批发订单' : '普通订单'}}</div>
            </div>
            <div class="detail_box_item">
              <div class="detail_box_item_left">支付时间</div>
              <div class="detail_box_item_right">{{parseTime(detail.orderPayTime)}}</div>
            </div>
            <div class="detail_box_item">
              <div class="detail_box_item_left">结算时间</div>
              <div class="detail_box_item_right">{{detail.settleTime ? parseTime(detail.settleTime) : '--'}}</div>
            </div>
            <div class="detail_box_item">
              <div class="detail_box_item_left">结算状态</div>
              <div class="detail_box_item_right">{{['','待结算','已结算'][detail.status]}}</div>
            </div>
            <div class="detail_box_item">
              <div class="detail_box_item_left" title="最终订单结算金额">结算金额</div>
              <div class="detail_box_item_right">¥{{detail.settleAmount.toFixed(2)}}</div>
            </div>
            <div class="detail_box_item">
              <div class="detail_box_item_left" title="支付后退款">支付后退款</div>
              <div class="detail_box_item_right">¥{{detail.settleAmountRefund.toFixed(2)}}</div>
            </div>
          </div>
          <div class="detail_box">
            <div class="detail_box_title">订单商品</div>
            <div class="detail_box_item_goods_wap" v-if="detail.goodsList && detail.goodsList.length">
              <div class="detail_box_item_goods" v-for="goods in detail.goodsList">
                <div class="goods_out_left">
                  <el-image style="width: 62px; height: 62px" :src="goods.cover"></el-image>
                </div>
                <div class="goods_out_right">
                  <div class="out_right_left">
                    <div class="goods_name">{{goods.goodsName}}</div>
                    <div class="gray">{{goods.skuName}}</div>
                  </div>
                  <div class="out_right_right">
                    <div class="goods_price">¥{{goods.purchasePrice.toFixed(2)}}</div>
                    <div class="gray"> x{{goods.goodsNum}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="detail_box_item">
              <div class="detail_box_item_left">货款总额</div>
              <div class="detail_box_item_right pull_right">¥{{detail.purchaseAmount.toFixed(2)}}</div>
            </div>
            <div class="detail_box_item">
              <div class="detail_box_item_left">运费</div>
              <div class="detail_box_item_right pull_right">¥{{detail.expressFeeAmount.toFixed(2)}}</div>
            </div>
          </div>
        </div>
        <div class="detail_right">
          <div class="detail_box">
            <div class="detail_box_title">退货/售后</div>
            <div class="divide-list" style="margin-bottom: 10px;"
                 v-if="detail.refundList && detail.refundList.length" v-for="refund in detail.refundList">
              <div class="detail_box_item">
                <div class="detail_box_item_left">售后单号</div>
                <div class="detail_box_item_right">{{refund.refundId}}</div>
              </div>
              <div class="detail_box_item">
                <div class="detail_box_item_left">退款商品</div>
                <div class="detail_box_item_right">
                  <div class="detail_box_item_goods">
                    <div class="goods_out_left">
                      <el-image style="width: 62px; height: 62px" :src="refund.cover"></el-image>
                    </div>
                    <div class="goods_out_right">
                      <div class="out_right_left">
                        <div class="goods_name">{{refund.goodsName}}</div>
                        <div class="gray">{{refund.skuName}}</div>
                      </div>
                      <div class="out_right_right">
                        <div class="goods_price">¥{{refund.purchasePrice.toFixed(2)}}</div>
                        <div class="gray">x{{refund.applyNum}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="detail_box_item">
                <div class="detail_box_item_left">退款时间</div>
                <div class="detail_box_item_right">{{parseTime(refund.refundSuccessTime)}}</div>
              </div>
            </div>
            <div class="detail_box_item">
              <div class="detail_box_item_left">货款退回金额</div>
              <div class="detail_box_item_right pull_right">¥{{detail.refundAmount.toFixed(2)}}</div>
            </div>
            <div class="detail_box_item">
              <div class="detail_box_item_left">运费退款</div>
              <div class="detail_box_item_right pull_right">¥{{detail.otherDeductionAmount.toFixed(2)}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fixed-action-right-bottom edit-goods-action">
      <el-button type="" @click="goBack">
        <el-icon class="el-icon-back"/>
        返回上页
      </el-button>
    </div>
  </div>
</template>

<script>

  import {URL} from '../../../config'
  import {parseTime, goBack} from '../../../assets/js/utils.js'

  export default {
    name: "supplierSettleDetail",
    data() {
      return {
        orderId: '',
        detail: {},
      }
    },
    created() {
      let orderId = this.$route.query.orderId;
      if (orderId) {
        this.showDetail(orderId)
      }
    },
    methods: {
      parseTime,
      goBack,
      showDetail(orderId) {
        this.axios.get(URL.suppWallet.getSupplierSettleDetail, {params: {orderId: orderId}}).then(res => {
          if (res.data) {
            this.detail = res.data;
          }
        })
      },
    }
  }
</script>

<style lang="less" scoped>
  @import "../../../assets/css/order";
</style>
